import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'

const { NODE_ENV, REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID } = process.env

/* eslint-disable no-console */
const VoidGA = {
  event: (...params) =>
    NODE_ENV === 'development'
      ? console.log('GoogleAnalytics.event:', params)
      : () => {},
  initialize: (...params) =>
    NODE_ENV === 'development'
      ? console.log('GoogleAnalytics.initialize:', params)
      : () => {},
  pageview: (...params) =>
    NODE_ENV === 'development'
      ? console.log('GoogleAnalytics.pageview:', params)
      : () => {},
  set: (...params) =>
    NODE_ENV === 'development'
      ? console.log('GoogleAnalytics.set:', params)
      : () => {},
}
/* eslint-enable no-console */

const GoogleAnalytics = REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
  ? ReactGA
  : VoidGA

GoogleAnalytics.initialize(REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID)

export const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    GoogleAnalytics.set({ page, ...options })

    GoogleAnalytics.pageview(page)
  }

  const TrackedComponent = (props) => {
    const {
      location: { pathname },
    } = props

    useEffect(() => {
      trackPage(pathname)
    }, [pathname])

    return <WrappedComponent {...props} />
  }

  TrackedComponent.propTypes = {
    location: PropTypes.objectOf(PropTypes.any),
  }

  TrackedComponent.defaultProps = {
    location: null,
  }

  return TrackedComponent
}

export default {
  ...GoogleAnalytics,
  withTracker,
}
